@import "support";

.header,
.headerResponsive,
.headerInpage {
  display: block;
  width: 100%;
  visibility: visible;
  left: 0px;
  top: 0px;
  position: fixed;
  z-index: 999;

  .logo {
    display: inline-block;
    vertical-align: top;

    .logoPositionHome {
      height: 65px;
      width: 250px;
      margin: 20px 0px 0px 0px;
      background-position: left top;
      background-size: 250px;
    }
  }

  .headerTopRight,
  .headerTopRightInpage {
    float: right;
    margin-top: 20px;

    .login_btnLabel {
      margin: 5px 0px 0px 5px;
      float: right;

      .login_button {
        padding: 7px 20px;
        font-family: $font-arial;
        font-size: $fontsize-16;
        color: $color-white;
        text-transform: uppercase;
        // border: .5px solid $color-blackbrown-lighter;
        border: none;
        background: $color-blue-bg-gradiant-dark;
        outline: none;
        border-radius: 50px;
        text-transform: capitalize;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }

      .login_button:hover {
        cursor: pointer;
        background: $color-blue-dark;
      }
    }

    .topnav {
      overflow: hidden;

      .dropdown {
        float: left;
        overflow: hidden;

        .dropbtn {
          border: none;
          outline: none;
          color: $color-blue-darker;
          padding: 13px 13px 11px 13px;
          font-family: $font-arial;
          font-size: $fontsize-16;
          margin: 0;
          background: transparent;
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
        }

        @-moz-document url-prefix() {
          .dropbtn {
            padding: 12px 16px 11px 16px;
          }
        }

        .dropbtn:hover {
          cursor: pointer;
        }

        .dropbtn.active {
          color: $color-red-dark;
        }

        .dropbtn.active:hover {
          color: $color-red-dark;
        }
      }

      .dropdown:hover .dropbtn {
        color: $color-red;
      }
    }
  }
}

.headerResponsive,
.headerInpage {
  display: none;
  position: fixed;

  .navBlockResponsive {
    padding: 7px 0px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
    background: $color-white;

    .loginBlkResponsive {
      position: absolute;
      right: 40px;
      top: 10px;
      z-index: 11;
      width: 95px;

      .LoginLogoutBtnIcon,
      .LogoutBtnIcon {
        position: absolute;
        top: 3px;
        border-radius: 50%;
        border: 2px solid $color-blue-dark;
        width: 37px;
        aspect-ratio: 1/1;
        background: $SignInIcon_SVG $color-white;
        background-size: 16px;
        background-position: center center;
        z-index: 10;
      }

      .LogoutBtnIcon {
        background: $SignInIcon_SVG $color-white;
        background-size: 16px;
        background-position: center center;
      }

      .loginLogoutBtnTxt {
        position: absolute;
        right: 0px;
        top: 8px;
        padding: 7px 14px 7px 20px;
        font-family: $font-arial;
        font-size: $fontsize-12;
        font-weight: bold;
        color: $color-white;
        background: $color-blue-dark;
        text-transform: uppercase;
        border-radius: 0px 10px 10px 0px;
      }
    }

    .topnav {
      overflow: hidden;
      margin-left: 0px;

      a {
        display: block;
        float: left;
        // color: $color-red;
        text-align: center;
        padding: 5px 8px 0px 0px;
        text-decoration: none;
        font-family: $font-arial;
        font-size: $fontsize-14;
        background: $color-white;
      }

      @-moz-document url-prefix() {
        a {
          padding: 7px 16px 7.3px 16px;
        }
      }

      .logoPosResponsive {
        height: 46px;
        width: 180px;
        background-position: left top;
        margin: 0px 0px 0px 10px;
      }

      @media ($smaller-than: $breakpoint-450) {
        .logoPosResponsive {
          height: 40px;
        }
      }

      .icon {
        display: none;
        height: 40px;
        width: 40px;
        background: $menuIcon_SVG;
        background-size: 22px;
        background-position: center center;
        margin-top: 5px;
      }

      .icon:hover {
        background: $menuIconRed_SVG;
        background-size: 22px;
        background-position: center center;
      }

      .dropdown {
        float: left;
        overflow: hidden;

        .dropbtn {
          border: none;
          outline: none;
          color: $color-blue-darker;
          padding: 13px 17px 11px 16px;
          font-family: $font-arial;
          font-size: $fontsize-17;
          margin: 0;
          background: transparent;
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
        }

        @-moz-document url-prefix() {
          .dropbtn {
            padding: 12px 16px 11px 16px;
          }
        }

        .dropbtn:hover {
          cursor: pointer;
          color: $color-red-darker;
          background-image: $bgTexure;
        }

        .dropbtn.active {
          color: $color-red-darker;
          background: $color-offwhitelight;
          background-image: $bgTexure;
        }

        .dropbtn.active:hover {
          background: $color-offwhitelight;
          background-image: $bgTexure;
          color: $color-red-darker;
        }
      }
    }

    @media screen and (max-width: $breakpoint-1300) {
      a {
        display: inline-block;
        vertical-align: middle;
      }

      .topnav a:not(:first-child),
      .dropdown .dropbtn {
        display: none;
      }

      .topnav a.icon {
        float: right;
        display: block;
      }

      .topnav.responsive {
        position: relative;
        margin-left: 0px;
        padding-left: 0px;
      }

      .topnav.responsive .icon {
        position: absolute;
        height: 40px;
        width: 40px;
        right: 0px;
        top: 0;
        background: $menuCrossRed_SVG;
        background-size: 16px;
        background-position: center center;
      }

      .topnav.responsive a {
        display: block;
        float: none;
        text-align: left;
      }

      .topnav.responsive .dropdown {
        float: none;
      }

      .topnav.responsive .dropdown-content {
        position: relative;

        a {
          color: $color-black;
          margin-left: -5px;
        }

        a::before {
          content: "\0023f5";
          margin-right: 3px;
        }

        a:last-child {
          border-bottom: 0.5px solid $color-lighterwhite;
        }
      }

      .topnav.responsive .dropdown .dropbtn {
        display: block;
        width: 100%;
        text-align: left;
        font-size: $fontsize-16;
        color: $color-blue-dark;
        padding-left: 10px;
      }
    }
  }
}

.headerInpage {
  display: block;
  background: $color-white;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);

  .logoPosInpage {
    height: 55px;
    width: 190px;
    background-position: left top;
    margin: 10px 0px 0px 0px;
  }

  .headerTopRightInpage {
    margin-top: 10px;
  }
}

@media ($smaller-than: $breakpoint-1023) {

  .header,
  .headerInpage {
    display: none;
  }

  .headerResponsive {
    display: block;
  }
}