// @charset 'UTF-8';
@import "support";

.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}

.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
  font-family: $font-arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //   background: red;
}

/* .slick-loading .slick-list {
    background: url(ajax-loader.gif) center center no-repeat #fff;
} */

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0;
}

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}

.slick-next:before,
.slick-prev:before {
  font-size: 30px;
  //   line-height: 1px;
  opacity: 0.75;
  color: red;
}

.slick-prev {
  left: -50px;
}

[dir="rtl"] .slick-prev {
  right: -50px;
  left: auto;
}

.slick-prev:before {
  // content: "\0025c2";
  color: $color-red;
  background: $color-offblack;
  padding: 5px 5px 0px 5px;
  border-radius: 5px;
  opacity: 0.3;
}

.slick-next:before,
[dir="rtl"] .slick-prev:before {
  // content: "\0025b8";
  color: $color-red;
  background: $color-offblack;
  padding: 5px 5px 0px 5px;
  border-radius: 5px;
  opacity: 0.3;
}

.slick-next {
  right: -50px;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -50px;
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-dotted.slick-slider {
  margin-bottom: 0px;
}

.slick-dots {
  bottom: -30px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 10px;
  padding: 0;
  cursor: pointer;
  background: $color-yellow-light;
  border-radius: 50%;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 16px;
  height: 16px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}

.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-size: $fontsize-28;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: $color-offwhite;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  font-size: 50px;
  color: $color-blackbrown-lighter;
  margin: -3px 0px 0px -5px;
}