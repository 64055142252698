@import "support";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Use the variables */
body {
  background: $color-white;
  font-family: $font-arial;
  font-size: $fontsize-16;
  width: 100%;
  height: 100%;
  color: $color-black;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

body>#root {
  height: 100%;
  width: 100%;
}

body>#root>#container {
  height: auto;
  min-height: 100%;
}

#container {
  color: $color-black;
  height: 100%;
  background: $color-white;
  background-image: $bgTexure;

  #content {
    padding-top: 45px;
    padding-bottom: 15px;
  }

  #content_fullHeader {
    padding-top: 65px;
    padding-bottom: 10px;
  }

  @media ($smaller-than: $breakpoint-1300) {
    #content_fullHeader {
      padding-top: 65px;
    }
  }

  @media ($smaller-than: $breakpoint-450) {
    #content_fullHeader {
      padding-top: 60px;
    }
  }
}

/*Breadcrumb Style*/
.commonPageHeader {
  display: block;
  text-align: center;
  text-transform: uppercase;
  background-image: $bgTexure;
  padding: 30px 0px;
  border-bottom: 1px solid $color-border-light;

  .pgHeadText {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-38;
    font-weight: bold;
    color: $color-blue-dark;
    margin-bottom: 5px;
  }

  .pgHeadText::after {
    content: ".";
    font-size: $fontsize-26;
    color: $color-red;
  }

  .pgHeadDescText,
  .pgHeadDescTextNormal {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-28;
    font-weight: bold;
    color: $color-blackbrown;
    width: 70%;
    margin: 0px auto;
  }

  .pgHeadDescTextNormal {
    text-transform: none;
  }

  @media ($smaller-than: $breakpoint-799) {

    .pgHeadText {
      font-size: $fontsize-30;
    }

    .pgHeadDescText,
    .pgHeadDescTextNormal {
      width: 80%;
      font-size: $fontsize-24;
    }
  }

  @media ($smaller-than: $breakpoint-450) {

    .pgHeadText {
      font-size: $fontsize-24;
    }

    .pgHeadDescText,
    .pgHeadDescTextNormal {
      width: 95%;
      font-size: $fontsize-18;
    }
  }
}

@media ($smaller-than: $breakpoint-450) {
  .commonPageHeader {
    padding: 15px 0px 20px 0px;
  }
}

/*Text Style Common*/

.txtBoldCaps {
  display: block;
  font-family: $font-arial;
  font-size: $fontsize-26;
  font-weight: bold;
  color: $color-blue-dark;
  margin: 20px 0px 10px 0px;
  text-transform: uppercase;
}

.txtBoldCaps::after {
  content: ".";
  font-size: $fontsize-26;
  color: $color-red;
}

.pgTxtNormal {
  display: block;
  font-family: $font-arial;
  font-size: $fontsize-17;
  text-align: justify;
  margin: 0px 0px 20px 0px;
  color: $color-blackbrown;
}

@media ($smaller-than: $breakpoint-799) {
  .txtBoldCaps {
    font-size: $fontsize-22;
  }

  .pgTxtNormal {
    font-size: $fontsize-15;
  }
}

@media ($smaller-than: $breakpoint-450) {
  .txtBoldCaps {
    font-size: $fontsize-17;
  }

  .pgTxtNormal,
  .pgTxtBold {
    font-size: $fontsize-14;
  }
}

ul.bulletPoints_un {
  list-style: none;
  margin: 0px 0px 30px 15px;
  font-family: $font-arial;
  font-size: $fontsize-17;
  color: $color-blackbrown;
  text-align: justify;

  .bp_indent_30 {
    margin-left: 30px;
    margin-bottom: 20px;
  }

  .bp_indent_60 {
    margin-left: 60px;
    margin-bottom: 25px;
  }

  .bp_indent_90 {
    margin-left: 90px;
    margin-bottom: 25px;
  }

  li.bp_squareOutline,
  li.bp_circleOutline,
  li.bp_circle,
  li.bp_circleFilled,
  li.bp_circleDouble {
    margin: 0px 0px 15px 30px;
  }

  li.bp_squareOutline::before,
  li.bp_circleOutline::before,
  li.bp_circle::before,
  li.bp_circleFilled::before,
  li.bp_circleDouble:before {
    color: $color-red;
    margin-left: -30px;
    padding-right: 15px;
  }

  li.bp_squareOutline::before {
    content: "\01f795";
  }

  li.bp_circleOutline::before {
    content: "\0025c9";
  }

  li.bp_circle::before {
    content: "\01f53e";
    font-size: $fontsize-16;
  }

  li.bp_circleFilled::before {
    content: "\0023fa";
  }

  li.bp_circleDouble::before {
    content: "\0025ce";
  }
}

@media ($smaller-than: $breakpoint-799) {
  ul.bulletPoints_un {
    font-size: $fontsize-15;
  }
}

@media ($smaller-than: $breakpoint-450) {
  ul.bulletPoints_un {
    font-size: $fontsize-14;
  }
}

/*Generic Pages*/
.genericPgBlk {
  display: block;
  margin: 30px 0px 20px 0px;
  padding-bottom: 25px;
}

/*Download*/
.download {
  display: block;
  margin: 30px 0px 10px 0px;
  padding-bottom: 10px;

  ul.downloadIIIcol {
    display: block;
    margin: 0px;
    padding: 0px;

    li.downloadIIIcol {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 3 - 14px);
      width: -webkit-calc(100% / 3 - 14px);
      margin: 0px 20px 20px 0px;
      border: 1px solid $color-border-light;
      background: $color-white;
    }

    li.downloadIIIcol:nth-child(3n + 0) {
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-1300) {
      li.downloadIIIcol {
        width: calc(100% / 2 - 10px);
        width: -webkit-calc(100% / 2 - 10px);
      }

      li.downloadIIIcol:nth-child(3n + 0) {
        margin-right: 15px;
      }

      li.downloadIIIcol:nth-child(even) {
        margin-right: 0px;
      }
    }

    @media ($smaller-than: $breakpoint-699) {
      li.downloadIIIcol {
        display: block;
        width: 100%;
        margin-right: 0px;
      }
    }
  }

  .downloadImg01,
  .downloadImg02,
  .downloadImg03,
  .downloadImg04,
  .downloadImg05 {
    display: block;
    width: 100%;
    height: 220px;
    border-bottom: 1px solid $color-border-light;
  }

  .downloadImg01 {
    background-image: url("../img/download_img01.jpg");
  }

  .downloadImg02 {
    background-image: url("../img/download_img02.jpg");
  }

  .downloadImg03 {
    background-image: url("../img/download_img03.jpg");
  }

  .downloadImg04 {
    background-image: url("../img/download_img04.jpg");
  }

  .downloadImg05 {
    background-image: url("../img/download_img05.jpg");
  }

  .downloadImg01,
  .downloadImg02,
  .downloadImg03,
  .downloadImg04,
  .downloadImg05 {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 0px;
  }

  .downloadContentBlk,
  .downloadContentBlk_minH160 {
    display: block;
    padding: 20px;
    min-height: 260px;

    .downloadBlkHead {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-18;
      font-weight: bold;
      margin-bottom: 15px;

      a {
        color: $color-red;
      }

      a:hover {
        color: $color-red-dark;
      }
    }

    .downloadBlkText {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-16;
      color: $color-blackbrown;
      margin-bottom: 15px;
    }

    .downloadAuthor {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-14;
      color: $color-blackbrown;
    }
  }

  .downloadContentBlk_minH160 {
    min-height: 160px;
  }
}

/*Leadership*/
.leadership {
  display: block;
  margin: 30px 0px 10px 0px;
  padding-bottom: 10px;

  .leadershipBlkTopTxt {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-16;
    width: 85%;
    text-align: center;
    margin: 0px auto 50px auto;
    color: $color-blackbrown;
  }

  ul.leadershipIIIcol {
    display: block;
    margin: 0px auto 0px auto;
    width: 70%;
    padding: 0px;

    li.leadershipIIIcol {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 3 - 30px);
      width: -webkit-calc(100% / 3 - 20px);
      margin: 0px 30px 20px 0px;
    }

    li.leadershipIIIcol:nth-child(3n + 0) {
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-699) {
      li.leadershipIIIcol {
        display: block;
        width: 100%;
        margin: 0px 0px 30px 0px;
        text-align: center;
      }
    }
  }

  @media ($smaller-than: $breakpoint-1200) {
    ul.leadershipIIIcol {
      width: 90%;
    }
  }

  @media ($smaller-than: $breakpoint-899) {
    ul.leadershipIIIcol {
      width: 100%;
    }
  }

  @media ($smaller-than: $breakpoint-799) {
    .leadershipBlkTopTxt {
      width: 100%;
      font-size: $fontsize-15;
      margin-bottom: 25px;
    }
  }

  .leadershipImgAlok,
  .leadershipImgSujay,
  .leadershipImgGopal {
    display: block;
    width: 200px;
    aspect-ratio: 1/1;
    border: 1px solid $color-border-light;
  }

  .leadershipImgAlok {
    background-image: url("../img/alok.png");
  }

  .leadershipImgSujay {
    background-image: url("../img/sujay.png");
  }

  .leadershipImgGopal {
    background-image: url("../img/gopal.png");
  }

  .leadershipImgAlok,
  .leadershipImgSujay,
  .leadershipImgGopal {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 0px;
  }

  @media ($smaller-than: $breakpoint-699) {

    .leadershipImgAlok,
    .leadershipImgSujay,
    .leadershipImgGopal {
      width: 250px;
      margin: 0px auto 5px auto;
    }
  }

  .leadershipName {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-16;
    font-weight: bold;
  }

  .leadershipDesg {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-13;
  }

}

/*Contact Us*/
.contact {
  display: block;
  margin: 30px 0px 30px 0px;

  ul.contactIIcol {
    display: block;

    li.contactIIcol {
      display: inline-block;
      vertical-align: top;
    }

    li.contactIIcol {
      width: 420px;
      margin-right: 20px;
    }

    li.contactIIcol:last-child {
      width: calc(100% - 440px);
      width: -webkit-calc(100% - 440px);
      margin: 0px;
    }

    @media ($smaller-than: $breakpoint-999) {

      li.contactIIcol,
      li.contactIIcol:last-child {
        display: block;
        width: 100%;
      }
    }
  }

  .contactShadowBlk {
    display: block;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    padding: 25px 25px;
    background: $color-white;
    margin-bottom: 20px;
  }

  @media ($smaller-than: $breakpoint-499) {
    .contactShadowBlk {
      padding: 20px 15px 15px 15px;
    }
  }

  ul.iconTextBlk {
    display: block;

    li.iconTextBlk {
      display: inline-block;
      vertical-align: top;
      width: 68px;
    }

    li.iconTextBlk:nth-child(even) {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 68px);
      width: -webkit-calc(100% - 68px);
      padding-top: 2px;
    }

    .contact_ph_icon,
    .contact_email_icon,
    .contact_address_icon {
      display: block;
      height: 55px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      border: 2px solid $color-red;
      background: $profilePhIcon_SVG;
      background-position: center center;
      background-size: 25px;
    }

    .contact_email_icon {
      background: $profileEmailIcon_SVG;
      background-position: center center;
      background-size: 25px;
    }

    .contact_address_icon {
      background: $profileAddressIcon_SVG;
      background-position: center center;
      background-size: 25px;
    }

    .contactTxtHead {
      display: block;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-15;
      color: $color-lightblack;
      margin-bottom: 7px;
    }

    .contPhEmailTxt,
    .contAddressTxt {
      display: block;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-18;
      color: $color-blue-dark;
      margin-bottom: 5px;
    }
  }

  .contactFormBlk {
    display: block;
    padding: 10px 25px 5px 25px;

    ul.contactForm {
      display: block;

      li.contactFormSingle {
        display: block;
        margin-bottom: 15px;
      }

      li.contactForm {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 2 - 10px);
        width: -webkit-calc(100% / 2 - 10px);
        margin: 0px 20px 30px 0px;
      }

      li.contactForm:nth-child(even) {
        margin-right: 0px;
      }

      @media ($smaller-than: $breakpoint-699) {
        li.contactForm {
          display: block;
          width: 100%;
          margin-right: 0px;
        }
      }

      .formContentHead {
        display: block;
        font-family: $font-arial;
        font-weight: bold;
        font-size: $fontsize-16;
        color: $color-lightblack-dark;
        margin-bottom: 2px;
      }

      .contactTxtField>input[type="text"],
      .contactTxtField>input[type="email"],
      .contactTxtField>textarea {
        font-family: $font-arial;
        font-size: $fontsize-16;
        border: 1px solid $color-offwhite-dark;
        background-image: $bgTexure;
        width: 100%;
        padding: 0px 10px 0px 10px;
        height: 43px;
        border-radius: 0px;
        box-shadow: 0px 2px 0px rgba(0, 68, 102, 0.8);
      }

      .contactTxtField>textarea {
        height: 80px;
        padding: 10px;
        box-sizing: border-box;
        resize: none;
        outline: none;
      }
    }

    .contact_button {
      display: block;
      width: 125px;
      height: 45px;
      margin: 0px auto;
      margin-top: 5px;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-20;
      color: $color-white;
      text-transform: capitalize;
      border: 0px;
      background: $color-red-dark;
      outline: none;
    }

    .contact_button:hover {
      cursor: pointer;
      background: $color-red-light;
    }
  }

  @media ($smaller-than: $breakpoint-999) {
    .contactFormBlk {
      padding: 10px 0px;
    }
  }
}

/*About Us*/
.about {
  display: block;
  margin: 30px 0px 10px 0px;
  padding-bottom: 10px;

  .aboutBlkTopTxt {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-16;
    width: 85%;
    text-align: center;
    margin: 0px auto 30px auto;
    color: $color-blackbrown;
  }

  ul.aboutIIcol {
    display: block;

    li.aboutIIcol {
      display: inline-block;
      vertical-align: top;
      width: -webkit-calc(100% / 2 - 15px);
      width: calc(100% / 2 - 15px);
      margin: 0px 30px 0px 0px;
    }

    li.aboutIIcol:last-child {
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-799) {

      li.aboutIIcol,
      li.aboutIIcol:last-child {
        display: block;
        width: 100%;
        margin: 0px 0px 20px 0px;
      }
    }
  }

  .aboutBlkHeadText {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-26;
    font-weight: bold;
    color: $color-blue-dark;
    margin: 0px 0px 10px 0px;
    text-transform: uppercase;
    text-align: center;
  }

  .aboutBlkHeadText::after {
    content: ".";
    font-size: $fontsize-26;
    color: $color-red;
  }

  .aboutBlkText {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-16;
    color: $color-blackbrown;
    text-align: center;
  }

  @media ($smaller-than: $breakpoint-799) {
    .aboutBlkTopTxt {
      width: 100%;
      font-size: $fontsize-15;
      margin-bottom: 25px;
    }
  }
}

/*Paza Markets style is in home.scss*/