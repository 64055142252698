@import "support";

.ipazalabsLogo {
  background: $ipazalabs_logo_SVG;
}

.ipazalabsWhiteLogo {
  background: $ipazalabs_logo_white_SVG;
}

/*General Responsiveness*/
@media ($bigger-than: $breakpoint-1300) {
  .widthfix {
    width: $width-1300px;
    margin: 0px auto;
  }

  .widthfix_10px {
    width: $width-1300px;
    margin: 0px auto;
  }
}

@media ($smaller-than: $breakpoint-1300) {
  .widthfix {
    width: auto;
    margin: 0px 10px;
  }

  .widthfix_10px {
    width: auto;
    margin: 0px 10px;
  }
}

/*Disable Background*/
.disable_bg,
.pop_disable_bg {
  background: $color-black;
  opacity: 0.3;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2450;
}

.pop_disable_bg {
  display: none;
  background: transparent;
  opacity: 1;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABtJREFUeNpiZGBgOMNABGBiIBKMKqSOQoAAAwBVNQDgpAAyvAAAAABJRU5ErkJggg==");
  z-index: 2510;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.loader-modal {
  position: fixed;
  background: transparent;
  color: $color-success;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*Common Form Styles*/
.formMandatory {
  display: inline-block;
  vertical-align: top;
  margin-left: 3px;
  height: 3px;
  font-family: $font-arial;
  font-size: $fontsize-24;
  color: $color-alert;
}

::-webkit-input-placeholder {
  color: $color-lightblack;
}

:-moz-placeholder {
  color: $color-lightblack;
}

::-moz-placeholder {
  color: $color-lightblack;
}

:-ms-input-placeholder {
  color: $color-lightblack;
}

select:required:invalid {
  color: $color-disable;
}

option {
  color: $color-blue-dark;
}

select::-ms-expand {
  display: none;
}

input[type="text"],
input[type="password"],
input[type="email"] {
  display: block;
  width: 100%;
  font-family: $font-arial;
  font-size: $fontsize-15;
  color: $color-black;
  padding: 10px 15px;
  border: 1px solid $color-blackbrown-light;
  background: $color-white;
  outline: 0;
}