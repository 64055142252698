@import "support";

/*SignIn*/
.si_su_Layout {
  overflow: hidden;
  z-index: 2500;
  position: fixed;
  right: -2000px;
  top: 0;
  width: 450px;
  height: 100%;
  padding: 0px 40px;
  background: $color-white;
  background-image: $bgTexure;

  .si_su_Close {
    position: absolute;
    z-index: 1000;
    top: 10px;
    right: 0px;
    cursor: pointer;
    font-family: $font-arial;
    font-size: $fontsize-32;
    color: $color-blue-dark;
    width: 40px;
    height: 40px;
  }

  .si_su_Close:hover {
    color: $color-red;
  }

  .si_su_Close:before {
    content: "x";
  }

  .si_su_ContentArea {
    display: block;
    overflow-y: hidden;

    ::-webkit-input-placeholder {
      color: $color-lightblack;
    }

    :-moz-placeholder {
      color: $color-lightblack;
    }

    ::-moz-placeholder {
      color: $color-lightblack;
    }

    :-ms-input-placeholder {
      color: $color-lightblack;
    }

    .textFieldButton {
      float: right;
      height: 20px;
      width: 70px;
      position: relative;
      top: 27px;
      right: 7px;
      margin-top: -20px;

      .button_getOTP {
        display: block;
        width: 100%;
        height: 26px;
        border: 0px solid $color-blue-lightest;
        border-radius: 5px;
        font-family: $font-arial;
        font-size: $fontsize-13;
        color: $color-white;
        outline: none;
        background: $color-blackbrown-lighter;
        text-align: center;
      }

      .button_getOTP:hover {
        background: $color-black;
        cursor: pointer;
      }
    }

    .signInTxtField>input[type="password"],
    .signInTxtField>input[type="text"] {
      font-size: $fontsize-15;
      width: 100%;
      padding: 0px 10px 0px 10px;
      height: 40px;
      border-radius: 7px;
      margin-bottom: 10px;
      // background: $bgTexure;
    }

    .si_su_Head {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-28;
      color: $color-red-dark;
      margin: 20px 0px 5px 0px;
    }

    .si_su_sub {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-16;
      color: $color-blackbrown-light;
      margin-bottom: 35px;
    }

    .si_su_sub a {
      text-decoration: none;
      font-weight: bold;
      color: $color-blue-dark;
      border-bottom: 1px dashed $color-blackbrown-light;
    }

    .si_su_sub a:hover {
      cursor: pointer;
      color: $color-black;
    }

    .si_su_method {
      display: block;
      padding: 0 0 5px 0;
      margin-bottom: 20px;
      border-bottom: 2px solid $color-offblack;

      .method_head {
        display: block;
        // font: $fontsize-17 $font-arial;
        color: $color-blue;
        margin-bottom: 10px;
      }

      .twitterLogo,
      .facebookLogo,
      .googleLogo {
        display: inline-block;
        vertical-align: top;
        height: 40px;
        width: 130px;
        margin-right: 10px;
        border-radius: 8px;
      }

      .twitterLogo {
        background: $twitter_SVG;
        background-position: center center;
      }

      .facebookLogo {
        background: $facebook_SVG;
        background-position: center center;
      }

      .googleLogo {
        background: $google_SVG;
        background-position: center center;
      }

      @media ($smaller-than: $breakpoint-699) {

        .twitterLogo,
        .facebookLogo,
        .googleLogo {
          width: calc(100% / 3 - 6px);
          width: -webkit-calc(100%/3 - 6px);
          margin-right: 5px;
          border-radius: 0px;
        }

        .googleLogo {
          margin-right: 0px;
        }
      }
    }

    .si_su_emailmethod {
      display: block;
      // font-family: $font-arial;
      font-size: $fontsize-21;
      color: $color-blackbrown;
      margin: 25px 0px 5px 0px;
    }

    .si_su_20margin_bottom {
      display: block;
      margin-bottom: 20px;
    }

    .si_su_Q {
      display: block;
      // font: $fontsize-18 $font-arial;
      color: $color-blackbrown-light;
      margin-bottom: 3px;

      .si_su_note {
        float: right;
        font: $fontsize-13 $font-arial;
        color: $color-lightblack;
        padding-top: 2px;
      }
    }

    .si_su_OTP_note {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-13;
      color: $color-lightblack;
      padding-top: 5px;
    }

    @media ($smaller-than: $breakpoint-699) {
      .si_su_Head {
        width: 90%;
        font-size: $fontsize-22;
      }

      .si_su_sub {
        margin-bottom: 20px;
      }

      .si_su_20margin_bottom {
        margin-bottom: 15px;
      }

      .si_su_OTP_note {
        font-size: $fontsize-13;
        padding-top: 25px;
      }

      input[type="text"] {
        font-size: $fontsize-16;
        padding: 8px 10px;
      }
    }

    .si_su_I_col {
      display: block;
    }

    ul.si_II_col {
      display: block;
      margin: 0px;
      padding: 0px;

      li.si_II_col {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 2 - 4px);
        width: -webkit-calc(100%/2 - 4px);
        margin: 0px 8px 0px 0px;
      }

      li.si_II_col:nth-child(even) {
        margin-right: 0px;
      }

      // li.si_su_II_col:last-child {
      //   margin-bottom: 10px;
      // }

      @media ($smaller-than: $breakpoint-699) {
        li.si_II_col {
          width: calc(100% / 2 - 8px);
          width: -webkit-calc(100%/2 - 8px);
          margin-right: 10px;
        }
      }
    }

    ul.su_II_col {
      display: block;
      margin: 20px 0px 0px 0px;
      padding: 0px;

      li.su_II_col {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 2 - 4px);
        width: -webkit-calc(100%/2 - 4px);
        margin: 0px 8px 10px 0px;
      }

      li.su_II_col:nth-child(even) {
        margin-right: 0px;
      }

      // li.su_II_col:last-child {
      //   margin-bottom: 10px;
      // }

      @media ($smaller-than: $breakpoint-699) {
        li.su_II_col {
          width: calc(100% / 2 - 8px);
          width: -webkit-calc(100%/2 - 8px);
          margin-right: 10px;
        }
      }
    }

    ul.si_su_II_col_by150 {
      display: block;
      margin: 0px;
      padding: 0px;

      li.si_su_II_col_by150 {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 165px);
        width: -webkit-calc(100% - 165px);
        margin-right: 10px;
      }

      li.si_su_II_col_by150:last-child {
        width: 150px;
        margin-right: 0px;
      }

      @media ($smaller-than: $breakpoint-699) {
        li.si_su_II_col_by150 {
          display: block;
          width: 100%;
          margin: 0px;
        }

        li.si_su_II_col_by150:last-child {
          width: 120px;
        }
      }
    }

    ul.si_su_II_col_40by60 {
      display: block;
      margin: 0px;
      padding: 0px;

      li.si_su_II_col_40by60 {
        display: inline-block;
        vertical-align: top;
        width: 120px;
        margin-right: 10px;
      }

      li.si_su_II_col_40by60:last-child {
        width: calc(100% - 130px);
        width: -webkit-calc(100% - 130px);
        margin-right: 0px;
      }

      @media ($smaller-than: $breakpoint-699) {

        li.si_su_II_col_40by60,
        li.si_su_II_col_40by60:last-child {
          display: block;
          width: 100%;
          margin: 0px;
        }

        li.si_su_II_col_40by60:last-child {
          margin-top: -15px;
        }
      }
    }

    .si_su_resendOTP {
      display: block;
      margin: 0px 0px 10px 0px;

      .button_si_su_resendOTP {
        display: block;
        width: 120px;
        padding: 9px 0px;
        border: 0px solid $color-blue-lightest;
        border-radius: 7px;
        // font-family: $font-arial;
        font-size: $fontsize-15;
        color: $color-white;
        outline: none;
        background: $color-blackbrown;
        text-align: center;
      }

      .button_si_su_resendOTP:hover {
        background: $color-red;
        cursor: pointer;
      }
    }

    ul.si_su_IV_col {
      display: block;
      margin: 0px;
      padding: 0px;

      li.si_su_IV_col {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 4 - 11px);
        width: -webkit-calc(100%/4 - 11px);
        margin-right: 10px;
        // font: $fontsize-17 $font-arial;
        color: $color-alert;
      }

      li.si_su_IV_col:last-child {
        margin-right: 0px;
      }

      li.si_su_IV_col.success {
        color: $color-success;
      }

      @media ($smaller-than: $breakpoint-699) {
        li.si_su_IV_col {
          display: block;
          width: 100%;
          margin: 0 0 15px 0;
        }
      }
    }

    input[type="checkbox"] {
      display: none;
      cursor: pointer;
    }

    input[type="checkbox"]:not([disabled])+label {
      cursor: pointer;
    }

    input[type="checkbox"]+label:before {
      content: "";
      display: inline-block;
      width: 18px;
      height: 18px;
      vertical-align: middle;
      margin: -7px 5px 0px 0;
      border: 1px solid $color-blue-lighter;
      border-radius: 3px;
      background: $color-white;
    }

    input[type="checkbox"]:checked+label:before {
      content: "\2714";
      color: $color-blackbrown;
      font: $fontsize-14 $font-arial;
      text-align: center;
      line-height: 18px;
    }

    .button_verify,
    .button_verify_pad9 {
      display: block;
      width: 100%;
      padding: 12px 0px;
      border: 0px solid $color-blue-lightest;
      border-radius: 7px;
      font-family: $font-arial;
      font-size: $fontsize-18;
      color: $color-white;
      outline: none;
      background: $color-blue-dark;
      text-align: center;
    }

    .button_verify_pad9 {
      padding: 9px 0px;
    }

    .button_verify:hover {
      background: $color-red;
      cursor: pointer;
    }

    .button_verify[disabled],
    .button_verify[disabled]:hover,
    .button_verify.disabled,
    .button_verify.disabled:hover {
      background: $color-lightblack;
      color: $color-offblack;
      cursor: default;
      text-shadow: none;
      position: static;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 0px dotted $color-disable;
    }

    @media ($smaller-than: $breakpoint-699) {
      .button_verify {
        margin-top: 10px;
        padding: 10px 0px;
        font-size: $fontsize-17;
      }
    }
  }

  .button_si_su_label {
    display: block;
    margin-bottom: 5px;

    .button_si_su {
      display: inline-block;
      vertical-align: middle;
      padding: 10px 25px;
      margin-right: 5px;
      border: 0px solid $color-blue;
      border-radius: 5px;
      font: $fontsize-22 $font-arial;
      color: $color-white;
      outline: none;
      background: $color-blue-navi;
      text-align: center;
    }

    .button_si_su:hover {
      background: $color-blue;
      cursor: pointer;
    }

    .button_si_su[disabled],
    .button_si_su[disabled]:hover,
    .button_si_su.disabled,
    .button_si_su.disabled:hover {
      background: $color-lightblack;
      color: $color-offblack;
      cursor: default;
      text-shadow: none;
      position: static;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 0px dotted $color-disable;
    }

    @media ($smaller-than: $breakpoint-699) {
      .button_si_su {
        padding: 8px 20px;
        font-size: $fontsize-20;
      }
    }
  }

  .si_su_terms {
    display: block;
    font: $fontsize-16 $font-arial;
    color: $color-lightblack;
  }

  .si_su_terms a {
    text-decoration: none;
    font-weight: bold;
    color: $color-lightblack;
    border-bottom: 1px dashed $color-lightblack;
  }

  .si_su_terms a:hover {
    cursor: pointer;
    color: $color-blackbrown-light;
  }

  @media ($smaller-than: $breakpoint-699) {
    .si_su_terms {
      font-size: $fontsize-14;
    }
  }
}

@media screen and (max-width: 699px) {
  .si_su_Layout {
    width: 400px;
    padding: 0px 20px 0px 20px;
  }
}