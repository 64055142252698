@import "support";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Use the variables */
body {
  font-family: $font-arial;
  font-size: $fontsize-16;
  width: 100%;
  height: 100%;
  color: $color-black;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

body>#root {
  width: 100%;
  height: 100%;
}

#homeVideo {
  margin: 0px;
  width: 100%;
  height: 100%;
  background: $color-white;

  ul.videoBG {
    display: block;
    position: relative;
    z-index: 9;
    height: 100%;

    li.videoBG {
      display: inline-block;
      vertical-align: top;
      width: -webkit-calc(90% / 2);
      width: calc(90% / 2);
      height: 100%;
      background: $homeCurveBgSmall;
      background-position: left bottom;
      background-size: 250px;
    }

    li.videoBG:last-child {
      width: -webkit-calc(110% / 2);
      width: calc(110% / 2);
      background: $homeCurveBgBig;
      background-position: right top;
    }
  }

  .videoContainer {
    position: relative;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 10;
    bottom: 0;
    right: 0;
    opacity: 0.6;
  }

  .videoContainer video {
    display: block;
    top: 0%;
    left: 0%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transition: 1s opacity;
  }

  .videoContainer video::-webkit-media-controls {
    display: none !important;
  }

  .video1-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    z-index: -100;
    background: transparent url(../img/bigscreen.jpg) no-repeat;
    background-size: cover;
  }

  .fallback_smallscreen {
    display: none;
    position: relative;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 10;
    bottom: 0;
    right: 0;
    opacity: 0.35;
    background: transparent url(../img/smallscreen.jpg) no-repeat;
    background-size: cover;
  }

  @media ($smaller-than: $breakpoint-799) {
    .videoContainer {
      display: none;
    }

    .videoContainer video {
      display: none;
    }

    .fallback_smallscreen {
      display: block;
    }
  }

  .videoContent_blk {
    position: absolute;
    top: 90px;
    width: 100%;
    z-index: 15;

    .widthfix_corousel {
      width: $width-1300px;
      margin: 0px auto;

      .widthfix_corousel_content {
        display: block;
        padding: 0px 0px;
      }

      @media ($smaller-than: $breakpoint-1300) {
        .widthfix_corousel_content {
          padding: 0px 15px;
        }
      }
    }

    @media ($smaller-than: $breakpoint-1400) {
      .widthfix_corousel {
        width: 100%;
      }
    }

    ul.homeText_Quote_blk {
      display: block;

      li.homeText {
        display: inline-block;
        vertical-align: top;
        width: -webkit-calc(100% / 2);
        width: calc(100% / 2);
        padding-top: 60px;

        .homeMainTxtBlock {
          display: block;

          h1 {
            display: block;
            font-family: $font-arial;
            font-weight: bold;
            font-size: $fontsize-45;
            color: $color-blue-dark;
            margin: 0px 0px 25px 0px;

            .mainTxt_line {
              display: block;
              margin-bottom: 4px;
            }
          }

          @media ($smaller-than: $breakpoint-799) {
            h1 {
              font-size: $fontsize-34;
              margin-bottom: 25px;
            }
          }

          @media ($smaller-than: $breakpoint-450) {
            h1 {
              font-size: $fontsize-24;
              margin-bottom: 18px;
            }
          }
        }

        .secondaryTxt {
          display: block;
          font-family: $font-arial;
          font-size: $fontsize-20;
          color: $color-blackbrown;
          margin: 0px 0px 30px 0px;
          opacity: 0.9;
          line-height: 30px;

          .secondaryTxt_line {
            display: block;
          }
        }

        @media ($smaller-than: $breakpoint-799) {
          .secondaryTxt {
            width: 100%;
          }
        }

        @media ($smaller-than: $breakpoint-450) {
          .secondaryTxt {
            font-size: $fontsize-18;
            line-height: 22px;
            margin-bottom: 20px;
          }
        }

        .homeButLabel {
          display: block;

          .but_learnMore {
            display: inline-block;
            vertical-align: top;
            padding: 12px 30px;
            border-radius: 30px;
            border: none;
            font-family: $font-arial;
            font-weight: bold;
            font-size: $fontsize-16;
            color: $color-blackbrown;
            background: $bgTransparent_85;
            opacity: 0.85;
            outline: none;
            transition: background 0.5s;
            -webkit-transition: background 0.5s;
          }

          .but_learnMore:hover {
            opacity: 1;
            cursor: pointer;
          }

          @media ($smaller-than: $breakpoint-799) {

            .but_learnMore {
              padding: 10px 25px;
              border-radius: 10px;
            }
          }

          @media ($smaller-than: $breakpoint-450) {

            .but_learnMore {
              padding: 8px 15px;
              height: auto;
              border-radius: 5px;
              font-size: $fontsize-16;
              background: $color-white;
              opacity: 1;
            }
          }
        }
      }

      li.homeCurveArtwork {
        display: inline-block;
        vertical-align: top;
        width: -webkit-calc(100% / 2);
        width: calc(100% / 2);

        .artworkPaza,
        .artworkHome,
        .artworkNFT {
          display: block;
          width: 100%;
          height: 388px;
        }

        .artworkPaza {
          background: $artworkPaza_SVG;
          background-position: left top;
          background-size: 580px;
        }

        .artworkHome {
          background: $artworkHome_SVG;
          background-position: left top;
          background-size: 580px;
        }

        .artworkNFT {
          background: $artworkNFT_SVG;
          background-position: left top;
          background-size: 580px;
        }
      }

      @media ($smaller-than: $breakpoint-1300) {

        li.homeText {
          display: block;
          width: 100%;
        }

        li.homeCurveArtwork {
          display: none;
        }
      }

      @media ($smaller-than: $breakpoint-450) {
        li.homeCurveArtwork {
          display: block;
          width: 100%;

          .artworkPaza,
          .artworkHome,
          .artworkNFT {
            display: block;
            width: 100%;
            height: 220px;
            margin-bottom: -15px;
          }

          .artworkPaza {
            background: $artworkPaza_SVG;
          }

          .artworkHome {
            background: $artworkHome_SVG;
          }

          .artworkNFT {
            background: $artworkNFT_SVG;
          }

          .artworkPaza,
          .artworkHome,
          .artworkNFT {
            background-position: center top;
            background-size: 310px;
          }
        }


      }
    }
  }

  @media ($smaller-than: $breakpoint-1300) {
    .videoContent_blk {
      top: 15%;
    }
  }

  @media ($smaller-than: $breakpoint-599) {
    .videoContent_blk {
      top: 12%;
    }
  }

  @media ($smaller-than: $breakpoint-450) {
    .videoContent_blk {
      top: 25px;
    }
  }
}

#home_bottom_blk {
  display: block;
  visibility: visible;
  width: 100%;
  height: 150px;
  left: 0px;
  top: 0px;
  position: fixed;
  z-index: 19;

  ul.home_bot_ver_col {
    display: block;
    margin: 10px auto 0px auto;
    padding: 0px;
    width: 80%;

    li.home_bot_ver_col {
      display: inline-block;
      vertical-align: top;
    }

    li.home_bot_ver_col:nth-child(odd) {
      width: -webkit-calc(100% / 3 - 1px);
      width: calc(100% / 3 - 1px);
      margin: 0px;
      text-align: center;
    }

    li.home_bot_ver_col:nth-child(even) {
      width: 1px;
      min-height: 130px;
      margin-top: 0px;
      background-image: linear-gradient(to bottom,
          transparent,
          $color-blue-lightest 50%,
          transparent);
    }

    .botIcon_blk {
      display: none;
      width: 70px;
      aspect-ratio: 1/1;
      margin: 0px auto 10px auto;
      border-radius: 50%;
      // background-image: linear-gradient(to bottom,
      //     $color-blue-light 15%,
      //     $color-blue-dark);

      .botIcon1,
      .botIcon2,
      .botIcon3,
      .botIcon4,
      .botIcon5 {
        width: 70px;
        aspect-ratio: 1/1;
      }

      .botIcon1 {
        background: $homeBotIcon3;
      }

      .botIcon2 {
        background: $homeBotIcon2_white;
      }

      .botIcon3 {
        background: $homeBotIcon3_white;
      }

      // .botIcon4 {
      //   background: $homeBotIcon2_white;
      // }

      // .botIcon5 {
      //   background: $homeBotIcon2_white;
      // }

      .botIcon1,
      .botIcon2,
      .botIcon3,
      .botIcon4,
      .botIcon5 {
        background-size: 60px;
        background-position: center center;
      }

      @media ($smaller-than: $breakpoint-799) {

        .botIcon1,
        .botIcon2,
        .botIcon3,
        .botIcon4,
        .botIcon5 {
          width: 60px;
          aspect-ratio: 1/1;
          background-size: 30px;
        }
      }
    }

    .botIcon_blk:hover {
      background: $color-blue-bg-gradiant-dark;
    }

    .botTextHead {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-60;
      font-weight: bold;
      color: $color-blue-darker;
      text-align: center;
      margin-top: 20px;
    }

    .botTextDesc {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-16;
      color: $color-blackbrown;
      text-align: center;
    }

    @media ($smaller-than: $breakpoint-799) {
      li.home_bot_ver_col:nth-child(even) {
        min-height: 100px;
        margin-top: 0px;
      }

      .botIcon_blk {
        width: 50px;
      }

      .botTextHead {
        font-size: $fontsize-40;
      }

      .botTextDesc {
        margin: 0px auto 15px auto;
        width: 80%;
      }
    }

    @media ($smaller-than: $breakpoint-450) {

      .botTextHead {
        font-size: $fontsize-28;
      }

      .botTextDesc {
        font-size: $fontsize-13;
      }

      // li.home_bot_ver_col:nth-child(even),
      // li.home_bot_ver_col:last-child {
      //   display: none;
      // }

      // li.home_bot_ver_col:nth-child(odd) {
      //   width: -webkit-calc(100% / 2);
      //   width: calc(100% / 2);
      //   margin-bottom: 15px;
      // }

      // .botTextDesc {
      //   width: 100%;
      //   color: $color-blackbrown-light;
      // }

      // .botIcon_blk {
      //   width: 60px;
      //   background: $color-blue-bg-gradiant-dark;
      // }
    }
  }

  @media ($smaller-than: $breakpoint-799) {
    ul.home_bot_ver_col {
      width: 90%;
    }
  }

  @media ($smaller-than: $breakpoint-450) {
    ul.home_bot_ver_col {
      width: 100%;
    }
  }

  // ul.home_bot_ver_col {
  //   display: block;
  //   margin: 10px 0px 0px 0px;
  //   padding: 0px;

  //   li.home_bot_ver_col {
  //     display: inline-block;
  //     vertical-align: top;
  //   }

  //   li.home_bot_ver_col:nth-child(odd) {
  //     width: -webkit-calc(100% / 5 - 1px);
  //     width: calc(100% / 5 - 1px);
  //     margin: 0px 0px 0px 0px;
  //     text-align: center;
  //   }

  //   li.home_bot_ver_col:nth-child(even) {
  //     width: 1px;
  //     min-height: 130px;
  //     margin-top: 30px;
  //     background-image: linear-gradient(to bottom,
  //         transparent,
  //         $color-blue-lightest 50%,
  //         transparent);
  //   }

  //   .botIcon_blk {
  //     display: block;
  //     width: 75px;
  //     aspect-ratio: 1/1;
  //     margin: 0px auto 5px auto;
  //     border-radius: 50%;
  //     background-image: linear-gradient(to bottom,
  //         $color-blue-light 15%,
  //         $color-blue-dark);

  //     .botIcon1,
  //     .botIcon2,
  //     .botIcon3,
  //     .botIcon4,
  //     .botIcon5 {
  //       width: 75px;
  //       aspect-ratio: 1/1;
  //     }

  //     .botIcon1 {
  //       background: $homeBotIcon1_white;
  //       background-size: 37px;
  //       background-position: center center;
  //     }

  //     .botIcon2 {
  //       background: $homeBotIcon2_white;
  //       background-size: 37px;
  //       background-position: center center;
  //     }

  //     .botIcon3 {
  //       background: $homeBotIcon2_white;
  //       background-size: 37px;
  //       background-position: center center;
  //     }

  //     .botIcon4 {
  //       background: $homeBotIcon2_white;
  //       background-size: 37px;
  //       background-position: center center;
  //     }

  //     .botIcon5 {
  //       background: $homeBotIcon2_white;
  //       background-size: 37px;
  //       background-position: center center;
  //     }

  //     @media ($smaller-than: $breakpoint-799) {

  //       .botIcon1,
  //       .botIcon2,
  //       .botIcon3,
  //       .botIcon4,
  //       .botIcon5 {
  //         width: 60px;
  //         aspect-ratio: 1/1;
  //         background-size: 30px;
  //       }
  //     }
  //   }

  //   .botIcon_blk:hover {
  //     background: $color-blue-bg-gradiant-dark;
  //   }

  //   .botTextHead {
  //     display: block;
  //     font-family: $font-arial;
  //     font-size: $fontsize-34;
  //     font-weight: bold;
  //     color: $color-blue-darker;
  //     text-align: center;
  //   }

  //   .botTextDesc {
  //     display: block;
  //     font-family: $font-arial;
  //     font-weight: bold;
  //     font-size: $fontsize-14;
  //     color: $color-lightblack;
  //     text-align: center;
  //   }

  //   @media ($smaller-than: $breakpoint-799) {
  //     li.home_bot_ver_col:nth-child(even) {
  //       min-height: 100px;
  //       margin-top: 0px;
  //     }

  //     .botIcon_blk {
  //       width: 50px;
  //     }

  //     .botTextHead {
  //       font-size: $fontsize-24;
  //     }

  //     .botTextDesc {
  //       font-size: $fontsize-12;
  //       margin: 0px auto 15px auto;
  //       width: 80%;
  //     }
  //   }

  //   @media ($smaller-than: $breakpoint-450) {

  //     li.home_bot_ver_col:nth-child(even),
  //     li.home_bot_ver_col:last-child {
  //       display: none;
  //     }

  //     li.home_bot_ver_col:nth-child(odd) {
  //       width: -webkit-calc(100% / 2);
  //       width: calc(100% / 2);
  //       margin-bottom: 15px;
  //     }

  //     .botTextDesc {
  //       width: 100%;
  //       color: $color-blackbrown-light;
  //     }

  //     .botIcon_blk {
  //       width: 60px;
  //       background: $color-blue-bg-gradiant-dark;
  //     }
  //   }
  // }
}

/*Bigger than 249px */
@media ($bigger-than: $breakpoint-249) {
  #home_bottom_blk {
    top: auto;
    bottom: 0px;
    position: absolute;
  }
}

/*Smaller than 1300px */
@media ($smaller-than: $breakpoint-1300) {
  #home_bottom_blk {
    height: auto;
  }
}

@media screen and (max-height: $breakpoint-499) {
  #home_bottom_blk {
    display: none;
  }
}

/*Paza Market*/
.pazamarketContainer,
.pazamarketContainerInpage {
  display: block;
  margin: 0px;
  padding: 80px 0px;
  background-image: $bgTexure;

  .pmHeadLn1 {
    display: block;
    text-align: center;
    font-family: $font-arial;
    font-size: $fontsize-17;
    font-weight: bold;
    color: $color-red;
    margin-bottom: 3px;
  }

  .pmHeadLn2 {
    display: block;
    text-align: center;
    font-family: $font-arial;
    font-size: $fontsize-26;
    font-weight: bold;
    color: $color-blue-dark;
    margin-bottom: 70px;
  }

  @media ($smaller-than: $breakpoint-799) {
    .pmHeadLn1 {
      font-size: $fontsize-15;
    }

    .pmHeadLn2 {
      font-size: $fontsize-22;
      margin-bottom: 40px;
    }
  }

  @media ($smaller-than: $breakpoint-450) {
    .pmHeadLn2 {
      font-size: $fontsize-20;
    }
  }

  ul.pm_ver_col {
    display: block;

    li.pm_ver_col {
      display: inline-block;
      vertical-align: top;
    }

    li.pm_ver_col:nth-child(odd) {
      width: -webkit-calc(100% / 5 - 3px);
      width: calc(100% / 5 - 3px);
      margin: 0px 0px 0px 0px;
      text-align: center;
    }

    li.pm_ver_col:nth-child(even) {
      width: 1px;
      min-height: 130px;
      margin-top: 30px;
      // background-image: linear-gradient(to bottom,
      //     transparent,
      //     $color-blue-lightest 50%,
      //     transparent);
    }

    .pmIcon1,
    .pmIcon2,
    .pmIcon3,
    .pmIcon4,
    .pmIcon5 {
      display: block;
      height: 100px;
      margin-bottom: 10px;
    }

    .pmIcon1 {
      background: $pazaMarketIcon1;
      background-size: 90px;
      background-position: top center;
    }

    .pmIcon2 {
      background: $pazaMarketIcon2;
      background-size: 90px;
      background-position: top center;
    }

    .pmIcon3 {
      background: $pazaMarketIcon3;
      background-size: 90px;
      background-position: top center;
    }

    .pmIcon4 {
      background: $pazaMarketIcon4;
      background-size: 100px;
      background-position: top center;
    }

    .pmIcon5 {
      background: $pazaMarketIcon5;
      background-size: 100px;
      background-position: top center;
    }

    .pmTextHead {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-20;
      font-weight: bold;
      color: $color-black;
      text-align: center;
      margin-bottom: 15px;
    }

    .pmTextDesc {
      display: block;
      width: 80%;
      margin: 0px auto;
      font-family: $font-arial;
      font-size: $fontsize-14;
      color: $color-lightblack;
      text-align: center;
    }

    @media ($smaller-than: $breakpoint-799) {
      li.pm_ver_col:nth-child(even) {
        display: none;
      }

      .pmIcon1,
      .pmIcon2,
      .pmIcon3,
      .pmIcon4,
      .pmIcon5 {
        height: 50px;
        background-size: 50px;
      }

      .pmTextHead {
        font-size: $fontsize-16;
        margin-bottom: 8px;
      }

      .pmTextDesc {
        width: 90%;
        font-size: $fontsize-12;
      }
    }

    @media ($smaller-than: $breakpoint-450) {

      li.pm_ver_col,
      li.pm_ver_col:nth-child(odd) {
        display: block;
        width: 100%;
        margin-bottom: 25px;
      }

      .pmIcon1,
      .pmIcon2,
      .pmIcon3,
      .pmIcon4,
      .pmIcon5 {
        height: 70px;
        background-size: 70px;
      }

      .pmTextHead {
        font-size: $fontsize-18;
        margin-bottom: 3px;
      }

      .pmTextDesc {
        width: 70%;
        font-size: $fontsize-14;
      }
    }

  }
}

.pazamarketContainerInpage {
  background-image: none;
}

@media ($smaller-than: $breakpoint-799) {

  .pazamarketContainer,
  .pazamarketContainerInpage {
    padding: 40px 0px;
  }
}

/*Technology*/
.techContainer {
  display: block;
  margin: 0px;
  padding: 70px 0px;
  background: $color-blue-bg-light;
  background-image: $bgTexure;

  .techHeadLn1 {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-17;
    font-weight: bold;
    color: $color-red;
    margin-bottom: 3px;
    text-transform: uppercase;
  }

  @media ($smaller-than: $breakpoint-799) {
    .techHeadLn1 {
      font-size: $fontsize-15;
    }
  }

  ul.techHeadLn_More_Blk {
    display: block;
    margin-bottom: 40px;

    li.techHeadLn2 {
      display: inline-block;
      vertical-align: top;
      width: -webkit-calc(100% - 400px);
      width: calc(100% - 400px);
      font-family: $font-arial;
      font-size: $fontsize-40;
      font-weight: bold;
      color: $color-blue-dark;
    }

    li.techMoreBtnBlk {
      display: inline-block;
      vertical-align: top;
      width: 400px;
      text-align: right;
    }

    @media ($smaller-than: $breakpoint-799) {
      li.techHeadLn2 {
        width: -webkit-calc(100% - 100px);
        width: calc(100% - 100px);
        font-size: $fontsize-22;
        padding-right: 20px;
      }

      li.techMoreBtnBlk {
        width: 100px;
      }
    }

    @media ($smaller-than: $breakpoint-450) {
      li.techHeadLn2 {
        display: block;
        width: 100%;
        padding: 0px;
        font-size: $fontsize-18;
      }

      li.techMoreBtnBlk {
        display: none;
      }
    }
  }

  @media ($smaller-than: $breakpoint-450) {
    ul.techHeadLn_More_Blk {
      margin-bottom: 20px;
    }
  }

  ul.tech_ver_col {
    display: block;
    margin: 0px;
    padding: 0px;

    li.tech_ver_col {
      display: inline-block;
      vertical-align: top;
      width: -webkit-calc(100% / 3 - 20px);
      width: calc(100% / 3 - 20px);
      margin: 0px 30px 0px 0px;
      background: $color-blue-bg-lightest;
      border: 1px solid $color-border-light;
      padding: 20px 20px 40px 20px;
      min-height: 450px;
    }

    li.tech_ver_col:nth-child(3n + 0) {
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-799) {
      li.tech_ver_col {
        display: block;
        width: 100%;
        margin: 0px 0px 30px 0px;
      }
    }

    @media ($smaller-than: $breakpoint-450) {
      li.tech_ver_col {
        padding: 15px;
        min-height: 0px;
      }
    }
  }

  ul.tech_HeadIconContainer {
    display: block;
    margin-bottom: 25px;

    li.tech_HeadTextBlk {
      display: inline-block;
      vertical-align: top;
      width: -webkit-calc(100% - 60px);
      width: calc(100% - 60px);
      margin-top: 18px;
      border-left: 6px solid $color-red;

      .tectHeadText {
        display: inline-block;
        font-family: $font-arial;
        font-size: $fontsize-16;
        font-weight: bold;
        color: $color-white;
        line-height: 40px;
        background: $color-blue-dark;
        padding: 0px 12px 0px 12px;
      }
    }

    li.tech_HeadIcon {
      display: inline-block;
      vertical-align: top;
      width: 60px;
      aspect-ratio: 1/1;
      border-radius: 50px;
      background-image: linear-gradient(to bottom,
          $color-orange 1%,
          $color-red);
      // background: $color-red;
    }

    .techIcon1,
    .techIcon2,
    .techIcon3 {
      width: 60px;
      aspect-ratio: 1/1;
    }

    .techIcon1 {
      background: $techIcon1_SVG;
      background-size: 25px;
      background-position: center center;
    }

    .techIcon2 {
      background: $techIcon2_SVG;
      background-size: 33px;
      background-position: center center;
    }

    .techIcon3 {
      background: $techIcon3_SVG;
      background-size: 25px;
      background-position: center center;
    }
  }

  .techDescText {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-15;
    color: $color-blackbrown-lighter;
    margin-bottom: 25px;
  }

  ul.tech_points {
    display: block;
    margin: 0px 0px 15px 0px;
    padding: 0px;

    li.tech_points {
      display: inline-block;
      vertical-align: top;
    }

    li.tech_points:nth-child(odd) {
      width: 15px;
      aspect-ratio: 1/1;
      background: $tickIcon_SVG;
      background-position: left center;
    }

    li.tech_points:nth-child(even) {
      width: -webkit-calc(100% - 20px);
      width: calc(100% - 20px);
      font-family: $font-arial;
      font-size: $fontsize-15;
      font-weight: bold;
      color: $color-blackbrown;
      margin-bottom: 15px;
      padding-left: 15px;
    }

    @media ($smaller-than: $breakpoint-450) {
      .techDescText {
        font-size: $fontsize-14;
        margin-bottom: 20px;
      }

      li.tech_points:nth-child(even) {
        font-size: $fontsize-14;
        margin-bottom: 10px;
      }
    }
  }

  .but_tech {
    padding: 12px 20px;
    border-radius: 15px;
    border: none;
    font-family: $font-arial;
    font-size: $fontsize-15;
    color: $color-white;
    background: $color-blue;
  }

  .but_tech:hover {
    background: $color-blue-dark;
    cursor: pointer;
  }

  @media ($smaller-than: $breakpoint-450) {
    .techDescText {
      font-size: $fontsize-14;
    }

    .but_tech {
      padding: 10px 15px;
      font-size: $fontsize-14;
      border-radius: 8px;
    }
  }
}

@media ($smaller-than: $breakpoint-799) {
  .techContainer {
    padding: 40px 0px;
  }
}

/*News*/
.newsContainer {
  display: block;
  margin: 0px;
  padding: 70px 0px;

  .newsHeadLn1 {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-17;
    font-weight: bold;
    color: $color-red;
    margin-bottom: 3px;
    text-transform: uppercase;
  }

  @media ($smaller-than: $breakpoint-799) {
    .newsHeadLn1 {
      font-size: $fontsize-15;
    }
  }

  ul.newsHeadLn_More_Blk {
    display: block;
    margin-bottom: 40px;

    li.newsHeadLn2 {
      display: inline-block;
      vertical-align: top;
      width: -webkit-calc(100% - 400px);
      width: calc(100% - 400px);
      font-family: $font-arial;
      font-size: $fontsize-40;
      font-weight: bold;
      color: $color-blue-dark;
    }

    li.newsMoreBtnBlk {
      display: inline-block;
      vertical-align: top;
      width: 400px;
      text-align: right;
    }

    @media ($smaller-than: $breakpoint-799) {
      li.newsHeadLn2 {
        width: -webkit-calc(100% - 100px);
        width: calc(100% - 100px);
        font-size: $fontsize-22;
        padding-right: 20px;
      }

      li.newsMoreBtnBlk {
        width: 100px;
      }
    }

    @media ($smaller-than: $breakpoint-450) {
      li.newsHeadLn2 {
        display: block;
        width: 100%;
        padding: 0px;
        font-size: $fontsize-18;
      }

      li.newsMoreBtnBlk {
        display: none;
      }
    }
  }

  ul.news_ver_col {
    display: block;
    margin: 0px;
    padding: 0px;

    li.news_ver_col {
      display: inline-block;
      vertical-align: top;
      width: -webkit-calc(100% - 530px);
      width: calc(100% - 530px);
      margin: 0px 30px 0px 0px;
    }

    li.news_ver_col:nth-child(2) {
      width: 500px;
      border: 0px;
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-799) {

      li.news_ver_col,
      li.news_ver_col:nth-child(2) {
        display: block;
        width: 100%;
        margin: 0px;
      }

      li.news_ver_col:nth-child(1) {
        margin-bottom: 20px;
      }
    }
  }

  .newsMainImg {
    display: block;
    width: 100%;
    height: 400px;
    background-image: url("../img/newsMainImg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 0px;
  }

  @media ($smaller-than: $breakpoint-450) {
    .newsMainImg {
      height: 150px;
    }
  }

  .newsMainImgDescBlk {
    display: block;
    border: 1px solid $color-border-light;
    padding: 25px;

    .newsMainHeadDate,
    .newsMainHeadDateText {
      display: inline-block;
      vertical-align: top;
      font-family: $font-arial;
      font-size: $fontsize-14;
      font-weight: bold;
      color: $color-red;
      text-transform: uppercase;
    }

    .newsMainHeadDateText {
      color: $color-lightblack;
    }

    .newsMainHeadDateText::before {
      content: "/";
      width: 100px;
      padding: 0px 10px;
    }

    .mainImgTextHead {
      display: block;
      margin: 25px 0px 10px 0px;
      font-family: $font-arial;
      font-size: $fontsize-24;
      font-weight: bold;
      color: $color-black;
    }

    .mainImgTextDesc {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-14;
      color: $color-lightblack;
    }

    @media ($smaller-than: $breakpoint-450) {

      .newsMainHeadDate,
      .newsMainHeadDateText {
        display: block;
      }

      .newsMainHeadDateText::before {
        display: none;
      }
    }
  }

  ul.subImgTxtBlk {
    display: block;
    margin: 0px 0px 43px 0px;
    padding: 0px;

    li.subImgTxtBlk {
      display: inline-block;
      vertical-align: top;
      width: 165px;
      margin: 0px;
    }

    li.subImgTxtBlk:nth-child(2) {
      width: -webkit-calc(100% - 185px);
      width: calc(100% - 185px);
      margin-left: 20px;
    }

    .newsSubImg01,
    .newsSubImg02,
    .newsSubImg03 {
      display: block;
      width: 165px;
      aspect-ratio: 1/1;
    }

    .newsSubImg01 {
      background-image: url("../img/newsSubImg01.jpg");
    }

    .newsSubImg02 {
      background-image: url("../img/newsSubImg02.jpg");
    }

    .newsSubImg03 {
      background-image: url("../img/newsSubImg03.jpg");
    }

    .newsSubImg01,
    .newsSubImg02,
    .newsSubImg03 {
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: 0px;
    }

    .subNoteText {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-11;
      font-weight: bold;
      color: $color-red;
      margin: 25px 0px 7px 0px;
      text-transform: uppercase;
    }

    .subHeadText {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-15;
      font-weight: bold;
      color: $color-blue-darker;
      margin: 0px 0px 15px 0px;

      a {
        color: $color-blue-darker;
        text-decoration: none;
      }

      a:hover {
        color: $color-red;
      }
    }

    .subDescText {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-14;
      color: $color-lightblack;
      margin-bottom: 10px;
    }

    @media ($smaller-than: $breakpoint-450) {

      li.subImgTxtBlk,
      li.subImgTxtBlk:nth-child(2) {
        display: block;
        width: 100%;
        margin: 0px;
        text-align: center;
      }

      .subDescText {
        margin: -10px 0px 35px 0px;
      }

      .newsSubImg01,
      .newsSubImg02,
      .newsSubImg03 {
        width: 175px;
        aspect-ratio: 1/1;
        margin: 0px auto -10px auto;
      }
    }
  }

  @media ($smaller-than: $breakpoint-799) {
    ul.subImgTxtBlk {
      margin-bottom: 20px;
    }
  }

  ul.subImgTxtBlk:last-child {
    margin-bottom: 0px;
  }

}

@media ($smaller-than: $breakpoint-799) {
  .newsContainer {
    padding: 40px 0px;
  }
}

.readMoreBlk {
  float: right;
  width: 110px;
  height: 30px;
  margin-right: 10px;

  .readMoreCircle {
    position: relative;
    height: 30px;
    aspect-ratio: 1/1;
    background: $color-blue-bg-lightest;
    border-radius: 50px;
    z-index: 10;
  }

  .readmoreText {
    position: absolute;
    font-family: $font-arial;
    font-size: $fontsize-15;
    font-weight: bold;
    color: $color-lightblack-dark;
    z-index: 11;
    padding: 0px 0px 0px 15px;
  }

  .readmoreText::after {
    content: '\002192';
    padding-left: 10px;
    font-size: $fontsize-24;
  }
}